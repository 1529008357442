<template>
  <div class="mui-content">
    <div class="product-body" :style="`height: ${height}px;`" v-if="orderInfo">
      <div class="order-reminder" v-if="orderReminder">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20201125161405322.png" />
        <div class="ivv-txt">{{ orderReminder }}</div>
      </div>
      <div class="mui-table-view address">
        <div class="point"><span class="icon-location"></span></div>
        <div class="info">
          <span>收货人: {{ orderInfo.address.uname }}</span>
          <span class="mi-pull-right">{{ orderInfo.address.pho }}</span>
        </div>
        <div class="detail">
          <span>收货地址: {{ orderInfo.address.FullAddress }}</span>
        </div>
      </div>

      <div class="product-list">
        <div class="brand-box" style="border-bottom: 1px solid #eee;">
          <img :src="brandId === 1 ? imageXM : (brandId === 3 ? imageFJS : imageMW)" />
          <label>{{ brandId === 4 ? '大健康' : '美妆' }}</label>
        </div>
        <product-item v-for="(item, index) in orderInfo.products" :data-item="item" :key="index" color="#FFB5BB" :width="125" :stock="false" :stepper="false" :count="true" price-text="" />
        <van-cell-group v-if="orderInfo.referUserInfo">
          <van-cell>
            <van-row>
              <van-col span="8">推荐人姓名：</van-col>
              <van-col span="16" align="right">{{ orderInfo.referUserInfo.userName }}</van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row>
              <van-col span="8">推荐人手机号：</van-col>
              <van-col span="16" align="right">{{ orderInfo.referUserInfo.userPhone }}</van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row>
              <van-col span="8">推荐人授权编码：</van-col>
              <van-col span="16" align="right">{{ orderInfo.referUserInfo.userCode }}</van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
        <van-cell-group class="order-info">

          <van-cell>
            <van-row>
              <van-col span="8">商品总额：</van-col>
              <van-col span="16" align="right" class="font-pink">￥{{ orderInfo.totalPrice }}</van-col>
            </van-row>
          </van-cell>
          <van-cell v-if="orderInfo.redBalance">
            <van-row>
              <van-col span="12">预存红包抵扣：</van-col>
              <van-col span="12" align="right" class="font-pink">￥{{ orderInfo.redBalance }}
                <van-icon name="question-o" @click="showPrestoreLayer = true" />
              </van-col>
            </van-row>
          </van-cell>
          <van-field v-model="buyerMessage" label="买家留言" placeholder="买家的留言备注"></van-field>
        </van-cell-group>
        <!--默认活动-->
        <template v-for="(act, index) in actGiftList">
          <!-- btnType 1、默认送2、勾选3、加减 -->
          <van-cell-group class="lottery-box" v-if="act.isShow" :key="index">
            <div class="subtitle">
              <div class="front-txt"><span class="text">{{ act.title }}</span></div>
              <div class="act-text" v-if="act.mbConsume > 0">
                当前可选赠品数量 : <span class="font-pink">{{ act.maxNum }}</span>
              </div>
            </div>
            <!-- :class="{'':item.sysMax <= 0}"-->
            <template v-for="(item, idx) in act.zpList">
              <div class="van-item" v-if="item.btnType === 1" :key="idx">
                <span>{{ item.name }}</span><span class="font-red" v-if="item.stockNum < 50">(库存:{{ item.stockNum
                }})</span>
                <span class="count" v-if="item.showPrice">{{ item.priceStr }}</span>
                <span class='count' v-else>x{{ item.maxNum }}</span>
              </div>
              <div class="van-item" v-else-if="item.btnType === 2" :key="item.name">
                <span>{{ item.name }}</span><span class="font-red" v-if="item.stockNum < 50">(库存:{{ item.stockNum
                }})</span>
                <van-checkbox v-model="item.checked" @change="giftNumCheckChange(index, item)">
                </van-checkbox>
                <span class='count'>x{{ item.maxNum }}</span>
              </div>
              <div class="van-item stepper-box" :key="item.mbId" v-else>
                {{ item.name }}<span class="font-red" v-if="item.stockNum < 50">(库存:{{ item.stockNum }})</span>
                <van-stepper v-model="item.defaultNum" :min="item.minNum" :max="item.maxNum" @change="giftNumStepperChange(index)" :class="{ 'hide-minus-btn': item.defaultNum <= 0 }" disable-input="true" />
              </div>
            </template>
          </van-cell-group>
        </template>
      </div>
      <van-popup v-model="isPayByPwd" round :close-on-click-overlay="false">
        <password :show="isPayByPwd" :payAmount="orderInfo.payAmount" :accountBalance="orderInfo.accountBalance" :mixedBalance="orderInfo.mixedAmount" @handletap="hanldePwdClick" />
      </van-popup>
    </div>
    <van-popup v-model="showPrestoreLayer" round>
      <prestore :phone="orderInfo ? orderInfo.phone : ''" @success="findSuccess" @cancel="showPrestoreLayer = false" />
    </van-popup>
    <div class="footer" v-if="orderInfo">
      <van-row class="mui-bar-tab" type="flex" justify="end">
        <van-col span="14">
          <span class="count">共{{ orderInfo.Total }}件</span>
          <span class="txt">待付款:</span>
          <span class='price font-pink'>¥{{ orderInfo.totalPrice }}</span>
        </van-col>
        <van-col span="8">
          <van-button type="default" @click="toPay">提交订单</van-button>
        </van-col>
      </van-row>
    </div>
    <template v-if="orderInfo">
      <SelectPayType :showPayLayer="showPayLayer" :payAmount="orderInfo.totalPrice" @close="onAppPayCancel()" @pay="onAppPay" :isApp="isInApp" />
    </template>
    <!--推荐人信息-->
    <van-popup v-model="showRecommendLayer" class="recommend-popup" :close-on-click-overlay="false" :close-on-popstate="true">
      <recommend-popup @onsure="onRecommendSure" @close="showRecommendLayer = false" />
    </van-popup>
  </div>
</template>

<script>
import brandFjs from "../../assets/images/brand-3.png";
import brandXk from "../../assets/images/brand-1.png";
import brandMw from "../../assets/images/brand-4.png"
import ProductItem from "../../components/Product/Index"
import password from "../../components/Order/Password.vue"
import Prestore from "../../components/FindPreStore.vue"
import SelectPayType from "../../components/Order/SelectPayType";
import RecommendPopup from "../../components/RecommendPopup.vue";
import { getCookie } from "../../utils/cookies";
import { wxPay } from "../../utils/wxPay";
import { deliveryMessage } from "../../utils/appUtil";
export default {
  name: "Confirm",
  components: { ProductItem, password, Prestore, SelectPayType, RecommendPopup },
  data() {
    return {
      goodList: '', //下单的商品
      addressInfo: null,
      orderInfo: null,
      buyerMessage: '', //买家留言
      payList: [{ name: "在线支付", id: 1 }],
      orderReminder: '', //下单提醒
      isRepeat: false,
      height: '',
      brandId: 3,
      checked: 0,
      imageFJS: brandFjs,
      imageXM: brandXk,
      imageMW: brandMw,
      isPayByPwd: false,
      showPrestoreLayer: false,
      referPhone: "",
      brandTypeId: 3,
      addressId: "",
      goodStr: "",
      showRecommendLayer: false,
      isLdAgencies: false,
      payType: 3,
      isInApp: '0',
      showPayLayer: false,
      orderId: "",
      actGiftList: [],  //活动赠品默认赠送列表
      isChooseAppPay: false,//是否选择了app支付
      payMayObj: null
    }
  },
  created() {
    let { goodId, num, brandId, inApp, referUserPhone } = this.$route.query;
    if (inApp === '1') this.isInApp = '1';
    if (referUserPhone) this.referPhone = referUserPhone;
    this.brandId = +brandId;
    this.brandTypeId = this.brandId === 4 ? 4 : 3;
    this.goodStr = `{ "${goodId}": ${num} }`
    this.getShopCartList(goodId, num);
    this.height = document.documentElement.clientHeight;
    this.getActivityPrizeList(goodId, num);
    this.getWXShow()
  },
  mounted() {
    let that = this;
    window.orderSuccess = () => {
      this.$router.push('/upgradeZone/success?orderId=' + that.orderId + "&inApp=1");
    }
    window.clearPrompt = () => {
      // this.$router.push('/Recharge/AnnualMeetingList?inApp=1')
    }
  },
  methods: {
    getWXShow() {
      this.get('/Base/Pay/GetPayWayIsOpen', "", 2).then(res => {
        if (res.code === 1) {
          let obj = res.response;
          this.payMayObj = obj;
        }
      })
    },
    getShopCartList: function () {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.post("/OrderGoods/Order/SettlementOrderInfo", {
        isJuhuiOrder: 1,
        orderProducts: this.goodStr,
        referUserPhone: this.referPhone,
        brandId: this.brandId,
        v: 1
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response;
          if (!data.address) {
            return this.$dialog.alert({ message: '蜜蛋小姐姐提示您，还未填写地址,点击前往', title: '提示' }).then(() => {
              window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/address_edit.html?orderType=98&brandId=" + this.brandId + "&param=" + this.goodStr + "&isInApp=" + this.isInApp;
            });
          }
          if (data.pushManPopup) {
            this.showRecommendLayer = true;
          }
          data.products.forEach(it => {
            it.goodsName = it.productName;
            it.goodsUrl = it.productUrl;
            it.actEffect = it.productEffect;
            it.goodsPrice = it.priceAgo
            it.specifications = it.productContent;
            it.goodsCount = 1;
          })
          data.Total = 1;
          this.addressId = data.address.id;
          if (data.mixedAmount >= data.payAmount) this.checked = 1;
          else if (data.accountBalance >= data.payAmount) this.checked = 2;
          else if (data.mixedAmount + data.accountBalance > data.payAmount) this.checked = 1;
          else data.disAccount = true;
          this.orderInfo = data;
        }
      });

    },
    //获取活动赠品
    getActivityPrizeList(goodId, num) {
      let arr = [{ ProductId: goodId, num }];
      this.post("/OrderGoods/Activity/GetActivityPrizeList", {
        brandId: this.brandId,
        productStr: JSON.stringify(arr),
        source: 2,
        isJuhuiOrder: 1
      }, {}, 2).then(res => {
        if (res.code === 1) {
          let list = res.response || [];
          list.forEach((item) => {
            let usedNum = 0;
            item.zpList.forEach((it) => {
              usedNum += it.defaultNum * it.prizeConsume;
              if (it.btnType === 3) {
                it.maxNum = Math.min.apply(Math, [it.maxNum, it.stockNum]);
              }
            });
            item.mbConsume -= usedNum;
            item.maxNum = item.mbConsume;
            item.unUpload = false;
          });
          if (list.length > 0) {
            this.actGiftList = this.actGiftList.concat(list);
          }
        }
      })
    },
    toPay() {
      if (this.orderInfo.pushManPopup & !this.referPhone) {
        this.showRecommendLayer = true;
        return;
      }
      this.$toast.loading({ message: '订单生成中...', duration: 0, overlay: true });
      this.post("/OrderGoods/Order/CreateOrder", {
        isUseAccountBalance: 0,
        isUseMixedBalance: 0,
        brandId: this.brandId,
        brandTypeId: this.brandTypeId,
        addressId: this.addressId,
        orderProducts: this.goodStr,
        payType: 3,
        materialId: "",
        orderSource: this.isInApp === '1' ? 101 : 1,
        isJoin: 0,
        ruleStr: this.getPrize(this.actGiftList), //赠品字符串
        ruleStrAdd: [],
        ruleStrAdd2: [],
        remark: this.buyerMessage,
        referPhone: this.referPhone,
        buyNumAdd: 0,
        isJuhuiOrder: 1
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let { orderId, state, msg } = res.response;
          if (state === 2) {
            this.$dialog.confirm({
              title: '提示',
              message: msg,
              confirmButtonText: "开旗舰店",
              cancelButtonText: "采购订货",
              confirmButtonColor: '#ff98a0'
            }).then(() => {
              if (this.isInApp === '1') {
                this.$router.push("/apply/pink?token=" + getCookie("E"))
              } else {
                this.$router.push("/apply/pink")
              }
            }).catch(() => {
              if (this.isInApp === '1') {
                deliveryMessage({ 'action': 'jumpStore' });
              } else {
                window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=" + this.brandId;
              }
            })
            return;
          } else if (state !== 1) {
            this.$dialog.alert({
              title: '提示',
              message: msg,
              confirmButtonColor: '#ff98a0'
            })
            return;
          }
          this.orderId = orderId;
          if (this.isInApp === '1') {
            this.showPayLayer = true;
          } else {
            this.getPayInfo(orderId);
          }
        } else {
          this.$dialog({
            title: '提示',
            message: res.msg,
            confirmButtonColor: '#ff98a0'
          })
        }
      });
    },
    getPrize: function (list, keyName) {
      let array = [];
      list.forEach((item) => {
        if (item.isShow && (!keyName || item.keyName === keyName)) {
          item.zpList.forEach(function (it) {
            if (it.defaultNum > 0 && it.stockNum > 0 && !item.unUpload) {
              array.push({ 'mbId': item.mbId, 'prizeId': it.zpId, 'count': it.defaultNum })
            }
          });
        }
      });
      return JSON.stringify(array);
    },
    getPayInfo(orderId) {
      this.$toast.loading({ duration: 0, message: '支付中...', overlay: true });
      let params = {
        orderId,
        payType: this.payMayObj && this.payMayObj.yeePay ? 3 : 1,
        v: 1
      }
      this.get('/OrderGoods/Pay/PayJuhuiOrder', params, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response.wechat;
          wxPay({
            appId: data.appId || data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
            paySign: data.paySign || data.sign, signType: data.signType, package: data.package
          }).then(() => {
            this.$router.push('/upgradeZone/success?orderId=' + orderId)
          }).catch(msg => {
            this.$dialog.alert({ title: '提示', message: msg }).then(() => {
              window.location.href = this.userHost + "/rolorweb/html/order/personal.html"
            })
          })
        }
      }).catch((err) => {
        console.log(err);
        this.$toast.clear()
      })
    },
    //预存红包找回成功
    findSuccess(amount) {
      this.orderInfo.redBalance = amount;
    },

    hanldePwdClick({ type, password }) {
      let { orderId, orderNum } = this.orderInfo;
      if (type === 0) {
        this.isPayByPwd = false;
        return this.$router.replace("/Recharge/order_detail?orderId=" + orderId + "&orderType=12")
      }
    },
    //app 支付
    onAppPay(payType) {
      this.isChooseAppPay = true;
      this.showPayLayer = false;
      let realPayType = payType === '1' ? 2 : 1;
      let message = { action: "jumpGroupPlusPay", data: { orderId: this.orderId, payType: realPayType } };
      deliveryMessage(message);
    },
    onAppPayCancel() {
      if (this.isChooseAppPay) {
        this.isChooseAppPay = false;
        return;
      }
      this.showPayLayer = false;
      let message = { action: "jumpGroupExchangePlusList", data: { brandId: this.brandId } };
      deliveryMessage(message);
    },
    //确认推荐人手机号
    onRecommendSure({ agentInfo }) {
      this.referPhone = agentInfo.userPhone;
      this.orderInfo.referUserInfo = agentInfo;
      this.showRecommendLayer = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-body {
  padding: 11px 15px 84px;
  text-align: left;
  background-color: #f8f8f8;
  box-sizing: border-box;
  overflow-y: scroll;

  .order-reminder {
    display: flex;
    margin: -11px -15px 5px;
    padding: 11px 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

    img {
      width: 43px;
      margin-right: 12px;
    }

    .ivv-txt {
      color: #ff98a0;
    }
  }

  .address {
    position: relative;
    border-radius: 6px;
    padding: 17px 15px 17px 58px;
    background-color: #fff;

    .point {
      position: absolute;
      top: 50%;
      left: 11px;
      width: 33px;
      height: 33px;
      text-align: center;
      margin-top: -16px;
      border-radius: 50%;
      background-color: #ffb5bb;

      .icon-location {
        color: #fff;
        font-size: 20px;
        line-height: 33px;

        &:before {
          content: '\e9f0';
        }
      }
    }

    .info {
      span {
        color: #666;
      }

      .mi-pull-right {
        float: right;
      }
    }

    .detail {
      padding-top: 3px;

      span {
        color: #999;
      }
    }
  }

  .product-list {
    margin-top: 10px;

    .brand-box {
      display: flex;
      align-items: center;
      padding: 11px 15px;
      border-radius: 6px 6px 0 0;
      background-color: #fff;

      img {
        width: 40px;
      }

      label {
        color: #666;
        font-size: 14px;
      }
    }

    .good-item {
      width: 100%;
      display: flex;
      padding: 10px 15px;
      position: relative;
      background-color: #fff;
      box-sizing: border-box;

      /deep/.price-box {
        color: #ffb5bb;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAA9AgMAAAD69FDPAAAACVBMVEUAAAAAAAAAAACDY+nAAAAAA3RSTlMIAQTqE2A+AAAAGklEQVQI12MIIAImMExgWADCeGADECowkAAAXQURodU+YFsAAAAASUVORK5CYII=')
          repeat-x;
        background-size: auto 100%;
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }

    .van-cell-group {
      overflow: hidden;
      margin-top: 10px;
      border-radius: 6px;

      .subtitle {
        color: #666666;
        font-size: 12px;
      }

      .van-cell {
        font-size: 12px;

        .van-cell__title {
          > span {
            color: #666;
          }
        }
      }

      .pay-check-list {
        .van-radio-group {
          justify-content: space-between;

          .van-radio {
            margin-right: 0;
            margin-bottom: 6px;
          }
        }
      }

      .reCommend {
        position: relative;
        padding: 0 15px 10px;

        .van-cell {
          padding: 10px 0;

          .van-cell__value {
            span {
              color: #666;
            }
          }

          &:after {
            border-width: 0;
          }
        }

        .van-swipe-box {
          position: absolute;
          top: 8px;
          right: 0;

          .van-switch--on {
            background-color: #ff98a0;
          }

          .van-switch__node {
            font-size: 24px;
          }

          .board-txt {
            display: inline-block;
            vertical-align: top;
          }
        }

        //.van-switch {
        //  position: absolute;
        //  top: 0;
        //  right: 0;
        //  .van-switch__node {
        //    font-size: 22px;
        //  }
        //}
        .van-loading {
          line-height: 110px;
          display: inline-block;
        }

        .agent-info {
          height: 110px;
          padding: 10px 15px;
          text-align: center;
          box-sizing: border-box;
          background-color: #f8f8f8;

          .mui-input-row {
            padding: 3px 0;
            text-align: left;

            label {
              color: #999;
              width: 65px;
              display: inline-block;
            }

            .value {
              color: #666;
            }
          }
        }
      }
    }

    .order-info {
      margin-top: 10px;

      .van-cell {
        .van-cell__title {
          span {
            color: #666;
          }
        }

        &.order-prompt {
          .van-cell__value {
            color: #ff98a0;
          }
        }

        &:after {
          border-color: #f1f1f1;
          transform: scaleY(1);
          -webkit-transform: scaleY(1);
        }
      }
    }
  }

  &.pay-box-fixed {
    .product-list {
      padding-top: 44px;
    }
  }
}

.mui-content {
  /*padding-bottom: 84px;*/

  .footer {
    .mui-bar-tab {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 44px;
      z-index: 100;
      padding: 0 15px;
      border-top: 1px solid #f1f1f1;
      background-color: #fff;

      .van-col {
        display: flex;
        font-size: 12px;
        align-items: center;
        letter-spacing: 1px;
        justify-content: flex-end;

        .count {
          color: #999999;
          padding-right: 5px;
        }

        .txt {
          color: #999999;
          font-size: 14px;
        }

        .price {
          font-size: 18px;
          margin-top: 5px;
        }

        .van-button {
          height: 33px;
          color: #fff;
          padding: 0 18px;

          line-height: 30px;
          border-radius: 33px;
          background-color: #ff98a0;

          span {
            line-height: 31px;
          }

          &.van-button--disabled {
            opacity: 1;
            background-color: #ccc;
          }
        }
      }
    }
  }

  .van-checkbox__icon--checked {
    .van-icon {
      border-color: #ff98a0;
      background-color: #ff98a0;
    }
  }

  .font-pink {
    color: #ff98a0;
  }

  .mui-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.iphoneX {
  .mui-content {
    /*padding-bottom: 100px;*/

    .footer {
      .mui-bar-tab {
        padding-bottom: 30px;
      }
    }
  }
}

.van-button {
  &:active:before {
    opacity: 0;
  }
}

.van-cell-group {
  overflow: hidden;
  margin-top: 10px;
  border-radius: 6px;

  .subtitle {
    overflow: hidden;
    padding: 15px 15px 3px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .front-txt {
      flex: 1;

      .text {
        color: #ff98a0;
        font-size: 10px;
        padding: 2px 10px;
        border-radius: 18px;
        border: 1px solid #ff98a0;
        display: inline-block;
      }
    }
  }

  .van-cell {
    .van-cell__title {
      > span {
        color: #666;
      }
    }

    .van-cell__value {
      > span {
        color: #ff98a0;
      }
    }
  }
}

//赠品
.lottery-box {
  position: relative;

  .subtitle {
    .van-checkbox {
      float: right;
      margin-top: 5px;
    }
  }

  .act-text {
    color: #b5b5b5;
    margin-left: 10px;
  }

  .add-rule {
    display: flex;
    padding: 6px 0;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;

    .remark {
      color: #ff98a0;
    }

    .add-money {
      font-size: 12px;
      margin-right: 0;
      padding-top: 0;
      padding-right: 15px;
    }
  }

  .van-item {
    color: #666;
    padding: 15px 50px 15px 15px;
    position: relative;

    .van-stepper {
      position: absolute;
      top: 7px;
      right: 15px;
      border: 0;
      padding: 0;
      height: 36px;
      width: 120px;
      margin-left: -7px;
      border-radius: 0;
      background-color: inherit;

      input {
        margin: 0;
        color: #333;
        width: 100%;
        border: none !important;
        padding: 0 32px !important;
        height: 35px;
        box-sizing: border-box;
        display: inline-block;
        background-color: transparent;
        -webkit-text-fill-color: #333;
        -webkit-opacity: 1;
      }

      button {
        position: absolute;
        z-index: 6;
        padding: 0;
        height: 100%;
        border: none;
        color: #ff98a0;
        font-size: 22px;
        background-color: transparent;

        &:before {
          width: auto;
          height: auto;
          background-color: transparent;
          font-family: rolormd !important;
        }
      }

      .van-stepper__plus {
        right: 0;

        &:before {
          content: '\e96c';
        }

        &:after {
          width: auto;
          height: auto;
          background-color: transparent;
        }

        &.van-stepper__plus--disabled {
          opacity: 0.6;
        }
      }

      .van-stepper__minus {
        left: 0;
        color: #aaa;

        &:before {
          content: '\ea10';
        }

        &.van-stepper__minus--disabled {
          opacity: 0.6;
        }
      }

      &.hide-minus-btn {
        .van-stepper__minus {
          display: none;
        }

        input {
          display: none;
        }
      }
    }

    .van-checkbox {
      float: right;
      margin-left: 10px;
    }

    .count {
      color: #999;
      position: absolute;
      top: calc(50% - 10px);
      right: 15px;

      &.under-line {
        border-bottom: 1px solid #999;
      }
    }

    &.shortage {
      color: #999;

      > span {
        color: #999;
      }
    }

    &.stepper-box {
      padding-right: 120px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 15px;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #f1f1f1;
    }

    &:last-child {
      &:after {
        height: 0;
      }
    }

    .select-gift {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% + 5px);

      .van-radio .van-icon-checked {
        color: #ff98a0;
      }

      .van-radio__input {
        font-size: 16px;
        position: relative;
        top: -3px;
        border: solid 5px transparent;
      }
    }
  }

  .add-money {
    color: #ff98a0;
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    margin-right: -105px;
  }

  > .add-money {
    padding: 10px 15px;
  }

  .remark {
    padding: 11px 15px;
  }
}

::v-deep .recommend-popup {
  background-color: transparent;
}

.icon--17:before {
  content: '\e9b4';
}

.icon--4:before {
  content: '\e9c5';
}

@media screen and (max-height: 500px) {
  .mui-backNone .rop-btn {
    padding-top: 30%;
  }
}

@media screen and (max-width: 360px) {
  .self-cont .self-prod li .prod-cont .mui-numbox {
    width: 90px;
    padding: 0 28px;
  }

  .self-cont .self-prod li .prod-cont .mui-btn {
    width: 28px;
  }
}
</style>